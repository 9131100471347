import { FormState, SlotServices } from '../../../../utils/state/types';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { CartFlow } from '../../../../types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import { FormStatus } from '../../../../types/form-state';
import { getContactDetailsWithFieldIds } from '../../../../utils/mappers/collapseForm.mapper';
import handleChoosePlanFlow from './handleChoosePlanFlow';
import { getSelectedServicesForBuyPlan } from '../../../../utils/payment';
import { handleOwnerFlow } from './handleOwnerFlow';
import { handlePreviewFlow } from './handlePreviewFlow';
import { handleTemplateFlow } from './handleTemplateFlow';
import { handleSingleSessionFlow } from './handleSingleSessionFlow';

export type OnSubmit = (
  submission: SubmissionResponse,
  cartFlow?: CartFlow,
) => Promise<void>;

enum FlowType {
  OWNER,
  CHOOSE_PLAN,
  SINGLE_SESSION,
  PREVIEW,
  TEMPLATE,
}

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async (submissionResponse, cartFlow) => {
    const [state] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter, bookingsDataCapsule, experiments } =
      actionFactoryParams.context;
    const { status, serviceData, formSelectedSlot } = state;

    if (status === FormStatus.IDLE) {
      if (submissionResponse.state.valid) {
        const submission = getSubmission(state, submissionResponse);

        await setFieldsValuesInStorage(
          wixSdkAdapter,
          bookingsDataCapsule,
          experiments,
          submission,
        );

        switch (
          getCurrentFlow(state, serviceData.slotServices, wixSdkAdapter)
        ) {
          case FlowType.CHOOSE_PLAN:
            return handleChoosePlanFlow(
              actionFactoryParams,
              serviceData,
              formSelectedSlot,
            );
          case FlowType.SINGLE_SESSION:
            return handleSingleSessionFlow(
              actionFactoryParams,
              submission,
              serviceData.slotServices,
              cartFlow,
              formSelectedSlot.v2Availability,
            );
          case FlowType.OWNER:
            return handleOwnerFlow(actionFactoryParams);
          case FlowType.PREVIEW:
            return handlePreviewFlow(actionFactoryParams);
          case FlowType.TEMPLATE:
            return handleTemplateFlow(actionFactoryParams);
        }
      }
    }
  };
}

function getCurrentFlow(
  state: FormState,
  slotServices: SlotServices,
  wixSdkAdapter: WixOOISDKAdapter,
): FlowType {
  const { isBookingsOnEcom } = state;

  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (getSelectedServicesForBuyPlan(slotServices)?.length) {
    return FlowType.CHOOSE_PLAN;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else if (wixSdkAdapter.isTemplateMode() && isBookingsOnEcom) {
    return FlowType.TEMPLATE;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}

function getSubmission(
  state: FormState,
  submissionResponse: SubmissionResponse,
) {
  const { isServiceInCart, serviceData, collapseFormValues } = state;
  if (isServiceInCart) {
    return {
      ...submissionResponse.submission,
      ...getContactDetailsWithFieldIds({
        form: serviceData.form,
        contactDetails: collapseFormValues,
      }),
    };
  }

  return submissionResponse.submission;
}
