import { ServicePayment } from '@wix/bookings-uou-types';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import {
  PaymentMethod,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import settingsParams from '../../../../components/BookingsForm/settingsParams';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import {
  isOfferedAsOneTimeOnly,
  isOfferedAsPricingPlanOnly,
  isServiceHavePricingPlans,
} from '../utils';

export const getDefaultPaymentOptionId = ({
  settings,
  servicePayment,
  pricingPlanDetails,
  memberships,
  isPricingPlanInstalled,
  isDynamicPricingCustomOptions,
}: {
  settings: ControllerFlowAPI['settings'];
  servicePayment: ServicePayment;
  isPricingPlanInstalled: boolean;
  pricingPlanDetails?: PaidPlans;
  memberships?: ListEligibleMembershipsResponse;
  isDynamicPricingCustomOptions?: boolean;
}) => {
  if (isDynamicPricingCustomOptions) {
    return ReservedPaymentOptionIds.SingleSession;
  }
  const planId = memberships
    ? memberships?.eligibleMemberships?.[0]?.id // todo: change to Selected when implemented
    : pricingPlanDetails?.defaultPlan?.paidPlan?.orderId;
  if (planId) {
    return planId;
  }
  if (isOfferedAsOneTimeOnly(servicePayment) || !isPricingPlanInstalled) {
    return ReservedPaymentOptionIds.SingleSession;
  }
  if (isOfferedAsPricingPlanOnly(servicePayment)) {
    return ReservedPaymentOptionIds.BuyAPricingPlan;
  }
  return settings.get(settingsParams.defaultPaymentMethod) ===
    PaymentMethod.SINGLE || !isServiceHavePricingPlans(servicePayment)
    ? ReservedPaymentOptionIds.SingleSession
    : ReservedPaymentOptionIds.BuyAPricingPlan;
};
