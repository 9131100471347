import {
  BookedSchedule,
  Booking,
  BookingSourceActor,
  BookingSourcePlatform,
  ContactDetails,
  CustomFormField,
  SelectedPaymentOption,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormNestedSlot } from '../../../types/formSelectedSlots';
import { mapFormNestedSlotToServerSlot } from '../../../utils/mappers/ambassador-bookings-v2.mapper';
import { mapDynamicParticipantsNumber } from '../../../utils/mappers/form-submission.mapper';
import { Service } from '../../../utils/mappers/service.mapper';
import { SlotService } from '../../../utils/state/types';
import { ServiceType } from '@wix/bookings-uou-types';

export const createBookingEntity = ({
  slotService,
  numberOfParticipants,
  additionalFields,
  contactDetails,
}: {
  slotService: SlotService;
  numberOfParticipants: number | undefined;
  additionalFields: CustomFormField[];
  contactDetails: ContactDetails;
}): Booking => {
  const selectedMembership = slotService.memberships?.eligibleMemberships?.find(
    (membership) => membership?.id === slotService.selectedPaymentOption.id,
  );
  const { participantsChoices, totalParticipants } =
    mapDynamicParticipantsNumber(
      slotService.dynamicPriceInfo?.selectedVariants,
    );
  const isCourse = slotService.service.type === ServiceType.COURSE;

  return {
    numberOfParticipants,
    participantsChoices,
    totalParticipants,
    bookingSource: {
      actor: BookingSourceActor.CUSTOMER,
      platform: BookingSourcePlatform.WEB,
    },
    contactDetails,
    additionalFields,
    selectedPaymentOption: selectedMembership
      ? SelectedPaymentOption.MEMBERSHIP
      : slotService.selectedPaymentType,
    bookedEntity: {
      ...(isCourse
        ? {
            schedule: mapBookedSchedule({
              service: slotService.service,
              slot: slotService.nestedSlot,
            }),
          }
        : {
            slot: mapFormNestedSlotToServerSlot(slotService.nestedSlot),
          }),
    },
  };
};

const mapBookedSchedule = ({
  service,
  slot,
}: {
  service: Service;
  slot: FormNestedSlot;
}): BookedSchedule => {
  return {
    serviceId: service.id,
    scheduleId: service.scheduleId,
    timezone: slot.timezone,
  };
};
