import { CreateActionParams } from '../actions';
import { SlotService } from '../../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../../utils';
import {
  PaymentOption,
  PaymentType,
  ReservedPaymentOptionLabels,
} from '../../../../types/types';
import { SelectedPaymentOption } from '../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type setServiceSelectedPaymentOptionType = (
  service: SlotService,
  paymentOption: PaymentOption,
) => void;

export function createSetServiceSelectedPaymentOption({
  internalActions: { updateServiceSlot },
}: CreateActionParams): setServiceSelectedPaymentOptionType {
  return async (service: SlotService, paymentOption: PaymentOption) => {
    let selectedPaymentType: SelectedPaymentOption;

    switch (paymentOption.type) {
      case PaymentType.SINGLE_SESSION: {
        if (paymentOption.label === ReservedPaymentOptionLabels.ONLINE) {
          selectedPaymentType = SelectedPaymentOption.ONLINE;
        }
        if (paymentOption.label === ReservedPaymentOptionLabels.OFFLINE) {
          selectedPaymentType = SelectedPaymentOption.OFFLINE;
        }
        break;
      }
      case PaymentType.BUY_PLAN: {
        selectedPaymentType = SelectedPaymentOption.MEMBERSHIP;
        break;
      }
      case PaymentType.USE_PLAN: {
        selectedPaymentType = SelectedPaymentOption.MEMBERSHIP;
        break;
      }
      default: {
        selectedPaymentType = SelectedPaymentOption.OFFLINE;
      }
    }

    updateServiceSlot(
      {
        selectedPaymentOption: paymentOption,
        selectedPaymentType: selectedPaymentType!,
      },
      getServiceSlotIdentifier(service.nestedSlot),
    );
  };
}
