import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { Submission } from '@wix/forms-ui/types';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { ExperimentsConsts } from '../consts/experiments';

export function getSessionValues<T = any>(
  wixSdkAdapter: WixOOISDKAdapter,
  key: BookingsQueryParams,
): T | undefined {
  const valueFromStorage = wixSdkAdapter.getFromSessionStorage(key);

  if (valueFromStorage) {
    return JSON.parse(valueFromStorage);
  }

  return undefined;
}

export function setSessionValues<T = any>(
  wixSdkAdapter: WixOOISDKAdapter,
  key: BookingsQueryParams,
  value: T,
) {
  wixSdkAdapter.setToSessionStorage(key, JSON.stringify(value));
}

export async function getFieldsValuesInStorage(
  wixSdkAdapter: WixOOISDKAdapter,
  bookingsDataCapsule: BookingsDataCapsule,
  experiments: Experiments,
  key: BookingsQueryParams,
) {
  const isSaveSubmissionWithDataCapsuleEnabled = experiments.enabled(
    ExperimentsConsts.SaveSubmissionWithDataCapsule,
  );

  if (isSaveSubmissionWithDataCapsuleEnabled) {
    return bookingsDataCapsule.getItem(key);
  }

  return getSessionValues(wixSdkAdapter, key);
}

export async function setFieldsValuesInStorage(
  wixSdkAdapter: WixOOISDKAdapter,
  bookingsDataCapsule: BookingsDataCapsule,
  experiments: Experiments,
  submission: Submission,
) {
  if (submission) {
    const isSaveSubmissionWithDataCapsuleEnabled = experiments.enabled(
      ExperimentsConsts.SaveSubmissionWithDataCapsule,
    );

    if (isSaveSubmissionWithDataCapsuleEnabled) {
      try {
        await bookingsDataCapsule.setItem(
          BookingsQueryParams.FILLED_FIELDS,
          submission,
        );
      } catch (e) {
        console.error('data capsule set value error', e);
      }
    } else {
      wixSdkAdapter.setToSessionStorage(
        BookingsQueryParams.FILLED_FIELDS,
        JSON.stringify(submission),
      );
    }
  }
}

export function setIsEcomInStorage(
  wixSdkAdapter: WixOOISDKAdapter,
  isEcom: string = 'false',
) {
  wixSdkAdapter.setToSessionStorage(BookingsQueryParams.ECOM, isEcom);
}
