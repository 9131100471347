import { FormNestedSlot } from '../../../types/formSelectedSlots';
import { Rate } from '@wix/ambassador-services-catalog-server/http';
import { OnError } from '../../types';
import { Payments } from '@wix/ambassador-checkout-server/types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { RateLabels } from '../../../utils/mappers/form-submission.mapper';
import { mapCouponServerErrorsAndReporter } from '../../../utils/errors/errors';
import { ServerErrorType } from '../../../types/errors';

export type GetPaymentDetailsArgs = {
  slot: FormNestedSlot;
  numberOfParticipants: number;
  rate: Rate;
  serviceId: string;
  couponCode?: string;
  email?: string;
  isFixedPrice: boolean;
  onError?: OnError;
};

export const getPaymentsDetails = async ({
  checkoutServer,
  authorization,
  reportError,
  slot,
  numberOfParticipants,
  rate,
  serviceId,
  couponCode,
  email,
  isFixedPrice,
  onError,
}: GetPaymentDetailsArgs & FormApiContext): Promise<Maybe<Payments>> => {
  const { scheduleId, startDate, endDate } = slot;
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const { checkoutOptions } = await checkoutServer
          .CheckoutBackend()({ Authorization: authorization })
          .checkoutOptions({
            scheduleId,
            couponCode,
            createSession: {
              rate,
              scheduleOwnerId: serviceId,
              start: {
                timestamp: startDate,
              },
              end: {
                timestamp: endDate,
              },
            },
            paymentSelection: {
              numberOfParticipants,
              ...(isFixedPrice ? { rateLabel: RateLabels.GENERAL } : {}),
            },
            ...(email && { email }),
          });
        return checkoutOptions?.payments;
      },
      mapError: (e) => mapCouponServerErrorsAndReporter(JSON.stringify(e)),
      fallback: undefined,
    },
    reportError,
  );

  if (error) {
    onError?.(error as unknown as ServerErrorType);
  }
  return data;
};
