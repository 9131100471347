import {
  FormPageData,
  IncompleteFormNestedSlot,
  IncompleteFormSelectedSlot,
  PageAPIData,
} from '../../types/types';
import { generateLineItemId } from '../../api/implementations/utils';
import {
  AvailabilityType,
  FormNestedSlot,
} from '../../types/formSelectedSlots';
import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { getServiceSlotIdentifier } from '..';

export const mapSlotAvailabilityToFormSelectedSlot = (
  pageData: PageAPIData,
): FormPageData => {
  const { slotAvailability, timezone } = pageData;
  const { openSpots, totalSpots, slot, isFromV2 } = slotAvailability || {};
  const { endDate, startDate, location } = slot || {};

  const formSelectedSlot = {
    timezone,
    ...(isFromV2 ? { v2Availability: isFromV2 } : {}),
    ...(openSpots ? { openSpots } : {}),
    ...(totalSpots ? { totalSpots } : {}),
    ...(endDate ? { endDate } : {}),
    ...(startDate ? { startDate } : {}),
    ...(location ? { location } : {}),
    nestedSlots: generateNestedSlots(pageData),
  };

  const dynamicPricePreSelection = generateDynamicPriceSelection(
    pageData,
    formSelectedSlot,
  );

  return {
    formSelectedSlot,
    timezone: pageData.timezone,
    isEcom: pageData.isEcom,
    dynamicPricePreSelection,
  };
};

export const generateNestedSlots = (
  pageData: PageAPIData,
): IncompleteFormNestedSlot[] => {
  const result: IncompleteFormNestedSlot[] = [];

  if (pageData.slotAvailability?.nestedSlots?.length) {
    result.push(
      ...pageData.slotAvailability.nestedSlots.map((slot): FormNestedSlot => {
        return {
          lineItemId: generateLineItemId(),
          availabilityType: AvailabilityType.GOLDEN,
          startDate: slot.start!,
          endDate: slot.end!,
          serviceId: slot.serviceId!,
          resource: slot.resource,
          sessionId: pageData.slotAvailability?.slot?.sessionId,
          scheduleId: pageData.slotAvailability?.slot?.scheduleId,
          timezone: pageData.timezone,
          location: pageData.slotAvailability?.slot?.location,
        };
      }),
    );
  } else {
    // we always work with nested slot also for old flow or one slot to be consisted
    result.push({
      lineItemId: generateLineItemId(),
      availabilityType: AvailabilityType.STANDARD,
      startDate: pageData.slotAvailability?.slot?.startDate!,
      endDate: pageData.slotAvailability?.slot?.endDate!,
      serviceId:
        pageData.slotAvailability?.slot?.serviceId! || pageData.serviceId,
      resource: pageData.slotAvailability?.slot?.resource,
      sessionId: pageData.slotAvailability?.slot?.sessionId,
      scheduleId: pageData.slotAvailability?.slot?.scheduleId,
      timezone: pageData.timezone,
      location: pageData.slotAvailability?.slot?.location,
    } as IncompleteFormNestedSlot);
  }

  if (pageData.additionalSlots?.length) {
    result.push(
      ...pageData.additionalSlots.map(
        (slotAvailability): IncompleteFormNestedSlot => {
          return {
            lineItemId: generateLineItemId(),
            availabilityType: AvailabilityType.STANDARD,
            startDate: slotAvailability?.slot?.startDate,
            endDate: slotAvailability?.slot?.endDate,
            serviceId: slotAvailability?.slot?.serviceId,
            resource: slotAvailability?.slot?.resource,
            sessionId: slotAvailability?.slot?.sessionId,
            scheduleId: slotAvailability?.slot?.scheduleId,
            timezone: pageData.timezone,
            location: slotAvailability?.slot?.location,
          } as IncompleteFormNestedSlot;
        },
      ),
    );
  }

  return result;
};

export const generateDynamicPriceSelection = (
  pageData: PageAPIData,
  formSelectedSlot: IncompleteFormSelectedSlot,
) => {
  const dynamicPricePreSelection: { [key: string]: DynamicPriceInfo } = {};

  pageData.dynamicPricePreSelection?.reduce((acc, curr) => {
    // if there is no slotAvailability, we are in a course flow which means that there is no dynamic price so nothing to do here
    if (pageData.slotAvailability) {
      const relevantSlot = formSelectedSlot.nestedSlots.find((nestedSlot) => {
        if (nestedSlot.startDate && nestedSlot.serviceId) {
          return (
            nestedSlot.startDate === curr.startDate &&
            nestedSlot.serviceId === curr.serviceId
          );
        }
        return false;
      });
      if (curr.dynamicPriceInfo && relevantSlot) {
        acc[getServiceSlotIdentifier(relevantSlot)] = {
          ...curr.dynamicPriceInfo,
          nonEditable:
            curr.dynamicPriceInfo.nonEditable ||
            !!pageData.slotAvailability.nestedSlots?.length,
        };
      }
    }
    return acc;
  }, dynamicPricePreSelection);

  return dynamicPricePreSelection;
};
