import { PlanWithDescription } from '../../../types/types';
import { FormApiContext } from '../utils';
import type {
  PublicPlan,
  QueryPublicPlansResponse,
} from '@wix/ambassador-pricing-plans-v2-plan/types';
import { queryPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';

export const getPublicPlans = async ({
  httpClient,
  wixSdkAdapter,
}: FormApiContext): Promise<PlanWithDescription[]> => {
  const allPlans = (
    await httpClient.request<QueryPublicPlansResponse>(queryPublicPlans({}))
  )?.data;

  return Promise.all(
    (allPlans.plans || []).map(async (plan) => {
      const { price, cycle, duration, freeTrial } =
        await getTranslatedPricingDetails(wixSdkAdapter, plan);
      return {
        ...plan,
        description: [price, cycle, duration, freeTrial]
          .filter((x) => x)
          .join(', '),
      } as PlanWithDescription;
    }),
  );
};

const getTranslatedPricingDetails = async (
  wixSdkAdapter: WixOOISDKAdapter,
  publicPlan: PublicPlan,
) => {
  const pricingPlansApi = await wixSdkAdapter.getPricingPlansApi();
  return pricingPlansApi.getTranslatedPricingDetails(publicPlan);
};
