import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormState, SlotService } from '../state/types';
import { widgetDefaults } from './consts';
import { VisitorLogger } from '@wix/yoshi-flow-editor';
import { Location as ServiceLocation } from '../mappers/service.mapper';
import { mapToArray } from '../index';

export interface FormBiLogger extends VisitorLogger {
  update: (state: FormState) => void;
  initialDefaultBiLogger: (state: FormState) => void;
}

export function createFormBiLogger({
  viewerBiLogger,
  wixSdkAdapter,
}: {
  viewerBiLogger: VisitorLogger;
  wixSdkAdapter: any;
}): FormBiLogger {
  const getSelectedLocation = (location: ServiceLocation) => {
    return location?.id || location?.name || location?.address;
  };
  const mapStateToDefaultBiParams = (state: FormState) => {
    const {
      formInputs: { numberOfParticipants },
      businessInfo,
      couponInfo,
      pricingPlanDetails,
      memberDetails,
      serviceData,
    } = state;
    // todo: Support Multi slot
    const singleSlotService = mapToArray<SlotService>(
      serviceData.slotServices,
    )[0];
    const { formSchema } = serviceData;
    const { service, selectedPaymentOption, nestedSlot, selectedPaymentType } =
      singleSlotService;

    const staffMemberIds = service.staffMembers
      .map((staffMember) => staffMember.id)
      .join(', ');

    const planId = pricingPlanDetails?.plans?.find(
      (plan) => plan?.paidPlan?.orderId === selectedPaymentOption.id,
    )?.paidPlan?.planId;
    const paymentDetails = planId || selectedPaymentOption.id;

    const numberOfFields = formSchema.fields?.length;
    const numberOfRequiredFields = formSchema.fields?.reduce(
      (requiredFieldSum, field) =>
        requiredFieldSum +
        (field.renderInfo?.validationProperties?.required ? 1 : 0),
      0,
    );

    return {
      pageName: widgetDefaults.pageName,
      sessionId: nestedSlot?.sessionId,
      startTime: nestedSlot?.startDate,
      staffId: staffMemberIds,
      currency: service.payment.paymentDetails.currency,
      price: service.payment.paymentDetails.price,
      depositAmount: service.payment.paymentDetails.minCharge,
      servicePaymentOptions: JSON.stringify(service.paymentTypes),
      selectedPaymentType,
      selectedLocation: getSelectedLocation(service.location),
      paymentDetails,
      serviceId: service.id,
      numParticipants: numberOfParticipants,
      serviceType: service.type,
      formId: formSchema.formId! as any,
      language: businessInfo?.language,
      isCouponCode: !!couponInfo.appliedCoupon?.couponDiscount,
      couponId: couponInfo.appliedCoupon?.couponId,
      smsCapability: businessInfo.isSMSReminderEnabled,
      isUoUloggedIn: !!memberDetails,
      contactId: memberDetails?.contactId,
      memberId: memberDetails?.id,
      numberOfRequiredFields,
      numberOfFields,
    };
  };

  const updateDefaultBiParams = (state: FormState) => {
    viewerBiLogger.updateDefaults(mapStateToDefaultBiParams(state));
  };

  const initialDefaultBiLogger = (state: FormState) => {
    viewerBiLogger?.updateDefaults?.({
      ...widgetDefaults,
      referralInfo: wixSdkAdapter.getUrlQueryParamValue(
        BookingsQueryParams.REFERRAL,
      ),
      ...mapStateToDefaultBiParams(state),
    });
  };

  return Object.assign(viewerBiLogger, {
    update: updateDefaultBiParams,
    initialDefaultBiLogger,
  }) as FormBiLogger;
}
