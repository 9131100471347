import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { getSiteRolloutStatus } from '@wix/ambassador-bookings-v1-site-rollout-status/http';

export type IsBookingsOnEcomArgs = { onError: OnError };

export const isBookingsOnEcom = async ({
  httpClient,
  reportError,
  onError,
}: IsBookingsOnEcomArgs & FormApiContext): Promise<boolean> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const { data: response } = await httpClient.request(
          getSiteRolloutStatus({}),
        );
        return response!.siteRolloutStatus!.isBookingPlatformReady!;
      },
      mapError: (e) => ({
        error: ServerErrorType.CANNOT_FETCH_ECOM_ROLLOUT_STATUS,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );
  if (error) {
    onError(error);
  }
  return data!;
};
