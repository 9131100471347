import { CreateActionParams } from '../../actions';

export async function handlePreviewFlow({
  getControllerState,
  context: { wixSdkAdapter },
}: CreateActionParams) {
  const [state] = getControllerState();
  const { isBookingsOnEcom } = state;
  if (isBookingsOnEcom) {
    await wixSdkAdapter.openEcomCheckoutPreviewModal();
  } else {
    await wixSdkAdapter.navigateToBookingsWithSuffix();
  }
}
