import { IHttpClient } from '@wix/yoshi-flow-editor';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { createGroupedBookings } from '@wix/ambassador-bookings-v2-booking/http';
import {
  Booking,
  ContactDetails,
  CreateGroupedBookingsRequest,
  CustomFormField,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { mapToArray } from '../../../utils';
import { createBookingEntity } from './utils';

export const sendCreateGroupBookingsRequest = async (
  httpClient: IHttpClient,
  serviceData: ServiceData,
  contactDetails: ContactDetails,
  additionalFields: CustomFormField[],
  numberOfParticipants: number | undefined,
  sendSmsReminder: boolean | undefined,
) => {
  const bookings: Booking[] = mapToArray<SlotService>(
    serviceData.slotServices,
  ).map((slotService) => {
    return createBookingEntity({
      slotService,
      numberOfParticipants,
      additionalFields,
      contactDetails,
    });
  });

  const createGroupedBookingsRequest: CreateGroupedBookingsRequest = {
    bookings,
    participantNotification: {
      notifyParticipants: true,
    },
    sendSmsReminder,
  };
  const result = await httpClient.request(
    createGroupedBookings(createGroupedBookingsRequest),
  );
  return result.data;
};
