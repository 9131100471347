import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { PaidPlans } from '@wix/ambassador-checkout-server/types';

export type GetPricingPlanDetailsArgs = {
  formSelectedSlot: FormSelectedSlot;
  onError?: OnError;
};
export const getPricingPlanDetails = async ({
  checkoutServer,
  authorization,
  reportError,
  formSelectedSlot,
  onError,
}: GetPricingPlanDetailsArgs & FormApiContext): Promise<Maybe<PaidPlans>> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const { checkoutOptions } = await checkoutServer
          .CheckoutBackend()({
            Authorization: authorization,
          })
          .checkoutOptions({
            createSession: {
              scheduleOwnerId: formSelectedSlot.nestedSlots[0].serviceId,
              start: {
                timestamp: formSelectedSlot.nestedSlots[0].startDate,
              },
            },
            paymentSelection: {
              numberOfParticipants: 1,
            },
          });
        return checkoutOptions?.paidPlans;
      },
      mapError: (e) => ({
        error: ServerErrorType.GENERIC_PRICING_PLAN_ERROR,
        shouldReport: true,
      }),
      fallback: {
        plans: [],
      },
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }
  return data;
};
